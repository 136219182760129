import styled, { css } from 'styled-components'

import hex2rgba from '../../utils/hex2rgba'
import { BP_SMALLEST, BP_SMALL } from '../../styles/theme'

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 15;
  background-color: ${props => props.theme.primary};
  min-height: 62px;
`

const Content = styled.div`
  margin: 0 auto;
  margin-bottom: 0.75rem;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media only screen and (max-width: ${BP_SMALL}) {
    position: inherit;
    flex-direction: column;
  }
`
const ContainerLogo = styled.div`
  cursor: pointer;
  color: ${props => props.theme.textLight};
  display: flex;
  align-items: center;
  & > img {
    height: 45px;
    @media only screen and (max-width: ${BP_SMALLEST}) {
      height: 30px;
    }
  }
  & > h2 {
    margin: 0;
    margin-left: 0.35rem;
  }
`
const SearchButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  padding-left: 0.75rem;
  color: ${props => hex2rgba(props.theme.backgroundLight, 0.8)};

  &:hover {
    color: ${props => props.theme.backgroundLight};
  }
  &:focus {
    outline: none;
  }
  ${props => {
    switch (props.hide) {
      case 'large':
        return css`
          display: none;
          @media only screen and (max-width: ${BP_SMALL}) {
            display: inherit;
          }
        `
      case 'small':
        return css`
          display: inherit;
          @media only screen and (max-width: ${BP_SMALL}) {
            display: none;
          }
        `
      default:
        return css``
    }
  }}
`
const ContainerSearchSmall = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${BP_SMALL}) {
    width: 100%;
  }
`

export { Container, Content, ContainerLogo, ContainerSearchSmall, SearchButton }
