import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { BP_MEDIUM } from '../../styles/theme'

const ContainerItems = styled.div`
  display: flex;
`

const LinkIn = styled(props => (
  <Link {...props} activeStyle={{ color: '#00D000' }} />
))`
  text-decoration: none;
  display: block;
  margin: 0 0.75rem;
  color: ${props => props.theme.textLight};
  ${props =>
    props.active === 'true'
      ? css`
          color: ${props => props.theme.green};
        `
      : null}
  &:hover {
    color: ${props => props.theme.green};
  }
  @media only screen and (max-width: ${BP_MEDIUM}) {
    width: fit-content;
    margin: 0 0.1rem;
  }
`
const LinkButton = styled.button`
  background-color: transparent;
  margin: 0 0.35rem;
  color: ${props => props.theme.textLight};
  font-family: 'Lato';
  font-weight: 300;
  font-size: 18px;
  border: none;
  padding: 1px 0 3px;
  &:hover {
    color: ${props => props.theme.green};
  }
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: ${BP_MEDIUM}) {
    font-size: 16px;
  }
`

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${props => props.theme.primary};
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  z-index: 1;
  & > a {
    float: none;
    padding: 10px 10px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  ${props =>
    props.right &&
    css`
      right: 0;
    `}
`

const Dropdown = styled.div`
  float: left;
  overflow: hidden;
  &:hover > ${DropdownContent} {
    display: block;
  }
  &:hover > ${LinkButton} {
    color: ${props => props.theme.green};
  }
  &:hover > ${LinkIn} {
    color: ${props => props.theme.green};
  }
`

export { ContainerItems, LinkIn, LinkButton, Dropdown, DropdownContent }
