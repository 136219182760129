import React from 'react'

import { Row, Col } from 'react-styled-flexboxgrid'

const Section = ({ children }) => (
  <section>
    <Row center="xs" middle="xs">
      <Col xs={11} lg={9}>
        {children}
      </Col>
    </Row>
  </section>
)

export default Section
