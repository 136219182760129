import styled from 'styled-components'
import hex2rgba from '../../utils/hex2rgba'

export default styled.button`
  border: none;
  color: ${props => props.theme.textLight};
  background-color: ${props => props.theme.green};
  font-weight: bold;
  font-size: 1.2rem;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  box-shadow: ${props => hex2rgba(props.theme.green, 0.39)} 0px 4px 14px 0px;
  &:hover {
    background: ${props => props.theme.greenDark};
  }
  &:focus {
    outline: none;
  }
`
