import React, { PureComponent } from 'react'
import { navigate } from 'gatsby'
import { Fade } from 'react-reveal'
import {
  InstantSearch,
  Highlight,
  connectStateResults,
} from 'react-instantsearch-dom'
import onClickOutside from 'react-onclickoutside'
import {
  ContainerContentSearch,
  CustomHits,
  SearchBoxCustom,
  HitTitle,
  HitDescription,
  PoweredByCustom,
} from './Search.styled'

const News = ({ hit }) => (
  <div key={hit.url} onClick={() => navigate(hit.url)}>
    <HitTitle>
      <Highlight attribute="title" hit={hit} />
    </HitTitle>
    <HitDescription>
      <Highlight attribute="content" hit={hit} />
    </HitDescription>
  </div>
)

const Content = connectStateResults(({ searchState, searchResults }) => {
  const hasResults = searchResults && searchResults.nbHits !== 0
  if (!searchState.query) return null
  return (
    <div>
      <div hidden={!hasResults}>
        <CustomHits hitComponent={News} />
      </div>
      <div hidden={hasResults}>
        <div style={{ backgroundColor: 'white', padding: '0 1rem' }}>
          No se encontraron resultados para "{searchState.query}"
        </div>
      </div>
    </div>
  )
})

class Search extends PureComponent {
  handleClickOutside = evt => {
    this.props.close()
  }
  render() {
    return (
      <InstantSearch
        appId={process.env.ALGOLIA_APP_ID}
        apiKey={process.env.ALGOLIA_SEARCH_KEY}
        indexName={process.env.ALGOLIA_INDEX}
      >
        <ContainerContentSearch>
          <Fade duration={500} top distance="10px" exit={false} unmountOnExit>
            <SearchBoxCustom translations={{ placeholder: 'Search' }} />
            <Content />
          </Fade>
          <PoweredByCustom />
        </ContainerContentSearch>
      </InstantSearch>
    )
  }
}

export default onClickOutside(Search)
