import React from 'react'
import {
  ContainerItems,
  LinkIn,
  Dropdown,
  DropdownContent,
  LinkButton,
} from './ListItems.styled'

import { links } from './utils'

const ListItems = ({ location }) => (
  <ContainerItems>
    {links.map(item => {
      const { key, url, label, dropdown, content, right } = item
      return !dropdown ? (
        <LinkIn
          key={key}
          to={url}
          active={
            location && location.pathname.split('/')[1] === key
              ? 'true'
              : 'false'
          }
        >
          {label}
        </LinkIn>
      ) : (
        <Dropdown key={key}>
          <LinkButton>{label}</LinkButton>
          <DropdownContent right={right}>{content}</DropdownContent>
        </Dropdown>
      )
    })}
  </ContainerItems>
)

export default ListItems
