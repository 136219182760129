import styled, { css } from 'styled-components'

const ContainerSubItem = styled.div`
  padding: 0 2rem 2rem;
  display: block;
  color: ${props => props.theme.textLight};
`

const ContentItem = styled.div`
  margin-left: 0.75rem;
  & > span {
    display: block;
  }
`

const ContainerLogos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const CustomLink = styled.a`
  text-decoration: none;
  margin: 0 1rem;
  color: ${props => props.theme.textLight};
  &:hover {
    color: ${props => props.theme.primaryLight};
  }
  ${props =>
    props.noMargin &&
    css`
      margin: 0;
    `};
  ${props =>
    props.color &&
    css`
      color: ${props.theme[props.color]};
    `}
`

export { ContainerSubItem, ContentItem, ContainerLogos, CustomLink }
