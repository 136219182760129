import styled from 'styled-components';
import Img from 'gatsby-image';
import { Col } from 'react-styled-flexboxgrid';

import image_bottom from '../../images/contact_background_footer.png';

import { BP_MEDIUM } from '../../styles/theme';

const Background = styled.div`
  /* background-image: url(${image_bottom});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const BackgroundImage = styled(Img)`
  position: absolute !important;
  top: 0;
  width: 100%
`;

const ContainerForms = styled(Col)`
  && {
    display: flex;
    justify-content: space-evenly;
    margin: 1rem 2rem;
    @media only screen and (max-width: ${BP_MEDIUM}) {
      flex-direction: column;
      padding: 1rem;
      align-items: center;
    }
  }
`;

export { Background, ContainerForms, BackgroundImage };
