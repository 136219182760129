import React from 'react'
import { ThemeProvider } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faChevronRight,
  faChevronLeft,
  faAt,
  faPhone,
  faBuilding,
  faMapMarkerAlt,
  faSearch,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'

import GlobalStyle from '../../styles/global'
import theme from '../../styles/theme'

import { ContainerPage } from './Layout.styled'
import Header from '../Header'
import Footer from '../Footer'
// import ModalSubscription from '../FooterContact/ModalSubscription'

library.add(
  fab,
  faChevronRight,
  faChevronLeft,
  faAt,
  faPhone,
  faBuilding,
  faMapMarkerAlt,
  faSearch,
  faTimes
)

const Layout = ({ children, ...props }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Header location={props.location} algolia={props.algolia} />
      <ContainerPage>{children}</ContainerPage>
      <Footer />
      {/* <ModalSubscription /> */}
    </>
  </ThemeProvider>
)

export default Layout
