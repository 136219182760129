import React, { Fragment } from 'react'
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { Row, Col } from 'react-styled-flexboxgrid'
import {
  ContainerFooter,
  SectionData,
  SectionDataItem,
  SectionCenter,
  LastSection,
  CustomTitle,
  CustomLink,
  InternalLink,
} from './Footer.styled'

import Icon from '../Common/Icon'
import FooterContact from '../FooterContact'

const Footer = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo_as_white.png" }) {
        childImageSharp {
          fixed(height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Fragment>
      <FooterContact />
      <ContainerFooter>
        <Row center="xs" middle="xs">
          <Col xs={11} lg={9}>
            <SectionData>
              <SectionDataItem>
                <Icon icon="map-marker-alt" size="lg" />
                <div style={{ marginLeft: '1rem' }}>
                  <CustomTitle>Dirección</CustomTitle>
                  <span>
                    Obrero Mundial 644, Col. Atenor Salas, CDMX, 03010 México
                  </span>
                </div>
              </SectionDataItem>
              <SectionDataItem>
                <Icon icon="at" size="lg" />
                <div style={{ marginLeft: '1rem' }}>
                  <CustomTitle>Email</CustomTitle>
                  <CustomLink href="mailto:contacto@ascg.mx" noMargin>
                    contacto@ascg.mx
                  </CustomLink>
                </div>
              </SectionDataItem>
              <SectionDataItem>
                <Icon icon="phone" size="lg" />
                <div style={{ marginLeft: '1rem' }}>
                  <CustomTitle>Teléfonos</CustomTitle>
                  <span>
                    +52 55 5859 4873 <br />
                    +52 55 5840 4611 <br />
                    +52 55 5859 9296
                  </span>
                </div>
              </SectionDataItem>
              <SectionDataItem>
                <Col>
                  <img
                    src={require('../../images/sms_latam.png')}
                    alt="SMS Lationamerica"
                    height={80}
                  />
                  <p style={{ color: '#A2A2A2', margin: 0 }}>
                    Estamos en 21 países y estamos cerca
                  </p>
                </Col>
              </SectionDataItem>
            </SectionData>
            <SectionCenter>
              <Row middle="xs" center="xs" style={{ color: 'white' }}>
                <Col lg={4}>
                  <a href="https://ascg.mx/" target="_blank" rel="noreferrer">
                    <Img
                      fixed={file.childImageSharp.fixed}
                      alt="AS Consulting logo"
                      objectFit="contain"
                    />
                  </a>
                </Col>
                <Col lg={2} style={{ textAlign: 'center', fontSize: '14px' }}>
                  <span>Miembro</span>
                </Col>
                <Col lg={6}>
                  <img
                    src={require('../../images/logo-SMS-blanco.png')}
                    height={35}
                    alt="SMS Latinoamerica"
                  />
                </Col>
              </Row>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CustomLink
                  href="https://www.facebook.com/pg/asnewsmx/"
                  target="_blank"
                  rel="noopener"
                  aria-label="Facebook"
                >
                  <Icon icon={['fab', 'facebook-f']} size="lg" />
                </CustomLink>
                <CustomLink
                  href="https://twitter.com/ASNewsMX"
                  target="_blank"
                  rel="noopener"
                  aria-label="Twitter"
                >
                  <Icon icon={['fab', 'twitter']} size="lg" />
                </CustomLink>

                <CustomLink
                  href="https://www.youtube.com/channel/UCvT3RN0VFM7eAQA3mgrEcjw"
                  target="_blank"
                  rel="noopener"
                  aria-label="Youtube"
                >
                  <Icon icon={['fab', 'youtube']} size="lg" />
                </CustomLink>
              </div>
            </SectionCenter>
            <LastSection>
              <span>
                COPYRIGHT © 2018 AS CONSULTING GROUP -{' '}
                <InternalLink to="/aviso-de-privacidad/">
                  Aviso de privacidad
                </InternalLink>
              </span>
            </LastSection>
          </Col>
        </Row>
      </ContainerFooter>
    </Fragment>
  )
}

export default Footer
