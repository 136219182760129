import React from 'react'
import { ContactContent, ContentCategories } from './DropdownContent'

export const links = [
  {
    key: 'categories',
    url: '',
    label: 'Categorías',
    dropdown: true,
    right: false,
    content: <ContentCategories />,
  },
  {
    key: 'about_us',
    url: '/quienes-somos/',
    label: '¿Quiénes somos?',
    dropdown: false,
    right: false,
    content: null,
  },
  {
    key: 'contacto',
    url: '',
    label: 'Contacto',
    dropdown: true,
    right: true,
    content: <ContactContent />,
  },
]

export const iconLinks = [
  {
    icon: 'facebook-f',
    url: 'https://www.facebook.com/pg/asnewsmx/',
  },
  {
    icon: 'twitter',
    url: 'https://twitter.com/ASNewsMX',
  },
  {
    icon: 'youtube',
    url: 'https://www.youtube.com/channel/UCvT3RN0VFM7eAQA3mgrEcjw',
  },
]
