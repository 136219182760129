import React from 'react'

import {
  ContainerSubItem,
  ContentItem,
  ContainerLogos,
  CustomLink,
} from './Content.styled'
import { LinkIn } from '../ListItems.styled'
import Icon from '../../Common/Icon'

import { iconLinks } from '../utils'

const ContentContact = () => (
  <ContainerSubItem>
    <h4>Teléfonos</h4>
    <ContentItem>
      <span>01 55 5859 4873</span>
      <span>01 55 5440 7436</span>
      <span>01 55 5859 9296</span>
      <span>01 55 5840 4611</span>
    </ContentItem>
    <h4>Correo</h4>
    <ContentItem>
      <CustomLink href="mailto:atencionaclientes@ascg.mx" noMargin>
        atencionaclientes@ascg.mx
      </CustomLink>
    </ContentItem>
    <h4>Publicar en AS News</h4>
    <ContentItem>
      <LinkIn to="/#contacto">Contacto</LinkIn>
    </ContentItem>
    <h3>Síguenos</h3>
    <ContentItem>
      <ContainerLogos>
        {iconLinks.map((item, index) => (
          <CustomLink key={index} href={item.url} target="_blank">
            <Icon icon={['fab', item.icon]} size="lg" />
          </CustomLink>
        ))}
      </ContainerLogos>
    </ContentItem>
  </ContainerSubItem>
)

export default ContentContact
