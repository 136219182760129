import React from 'react'
import _ from 'lodash'
import { StaticQuery, graphql } from 'gatsby'
import { LinkIn } from '../ListItems.styled'

const ContentCategories = () => (
  <StaticQuery
    query={categoriesQuery}
    render={data => {
      const categories = _.get(data, 'allContentfulCategory.edges')
      return categories.map(({ node }) => (
        <LinkIn key={node.slug} to={`/noticias/${node.slug}`}>
          {node.title}
        </LinkIn>
      ))
    }}
  />
)

export default ContentCategories

const categoriesQuery = graphql`
  query CategoriesLinks {
    allContentfulCategory(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
