import React, { Component } from 'react'
import _ from 'lodash'
import { Form } from './FormFields.styled'
import Button from '../Common/Button.styled'

import getField from '../../utils/getField'
import { validateFields } from '../../utils/validateFields'

class FormFields extends Component {
  state = {
    formValues: {},
    errors: {},
  }
  setFormValue = (value, name) => {
    this.setState(prevState => ({
      formValues: {
        ...prevState.formValues,
        ...{ [name]: value },
      },
    }))
  }

  componentDidUpdate(prevProps) {
    if (this.props.clearValues !== prevProps.clearValues) {
      this.setState({ formValues: {} })
    }
  }

  setFormErrors = errors => {
    this.setState({ errors })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { formValues } = this.state
    const errors = validateFields(formValues, this.props.fields)
    this.setState({ errors }, () => {
      if (_.isEmpty(this.state.errors)) {
        this.props.submit(formValues)
      }
    })
  }
  renderField = field => {
    const { formValues, errors } = this.state

    return getField({
      ...field,
      key: field.name,
      onChange: this.setFormValue,
      setFormErrors: this.setFormErrors,
      value: formValues[field.name],
      color: this.props.color,
      errors,
    })
  }
  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        {this.props.fields.map(field => this.renderField(field))}
        <Button type="submit" small>
          {this.props.buttonText}
        </Button>
      </Form>
    )
  }
}

export default FormFields
