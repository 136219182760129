import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { createNotification } from '../../utils/createNotification'
import { contactSchema } from './utils'

import FormFields from '../FormFields'

class ContactForm extends Component {
  static propTypes = {
    actionAfterSubmit: PropTypes.func,
  }

  static defaultProps = {
    actionAfterSubmit: () => {},
  }
  state = {
    loading: false,
    clearValues: false,
  }
  handleSubmit = form => {
    this.setState({ loading: true }, async () => {
      try {
        await axios({
          method: 'post',
          baseURL:
            'https://gaarh9ipf4.execute-api.us-east-1.amazonaws.com/prod/',
          url: '/submit',
          data: { ...form },
          auth: {
            username: 'ASConsulting_Group',
            password: process.env.API_PASSWORD_SEND_EMAIL,
          },
        })
        createNotification('success', {
          title: 'Enviado correctamente!',
          message: '¡Se ha enviado con exito!',
        })
        this.setState(
          {
            loading: false,
            clearValues: !this.state.clearValues,
          },
          () => this.props.actionAfterSubmit()
        )
      } catch (err) {
        console.log('Error', err)
        createNotification('error', { title: 'Hubo un error', message: err })
        this.setState({ loading: false })
      }
    })
  }
  render() {
    const { color = '' } = this.props
    return (
      <FormFields
        submit={this.handleSubmit}
        fields={contactSchema}
        buttonText="Enviar"
        clearValues={this.state.clearValues}
        color={color}
      />
    )
  }
}

export default ContactForm
