import React, { PureComponent } from 'react';
import moment from 'moment';
import { navigate } from 'gatsby';

import { Container, Content, ContainerLogo, SearchButton, ContainerSearchSmall } from './Hedaer.styled';
import Icon from '../Common/Icon';
import Section from '../Common/Section';
import Search from '../Search';
import ListItems from './ListItems';

import 'moment/locale/es';
moment.locale('es');

class Header extends PureComponent {
	state = {
		openSeachBar: false
	};
	closeSearchBar = () => {
		this.setState({ openSeachBar: false });
	};
	render() {
		const { location } = this.props;
		return (
			<Container>
				<Section>
					<div
						style={{
							textAlign: 'right',
							fontSize: 'x-small',
							textTransform: 'uppercase',
							color: 'white'
						}}
					>
						{moment().format('DD / MMMM / YYYY')}
					</div>
					<Content>
						<ContainerSearchSmall>
							<ContainerLogo onClick={() => navigate('/')}>
								<img src={require('../../images/icon_asnews_white.png')} alt="AS News Logo" />
								<h2>News</h2>
							</ContainerLogo>
							<SearchButton
								onClick={() => this.setState({ openSeachBar: true })}
								hide="large"
								aria-label="Buscar"
							>
								<Icon icon="search" size="2x" />
							</SearchButton>
						</ContainerSearchSmall>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ListItems location={location} />
							<SearchButton
								onClick={() => this.setState({ openSeachBar: true })}
								hide="small"
								aria-label="Buscar"
							>
								<Icon icon="search" size="2x" />
							</SearchButton>
							{this.state.openSeachBar && <Search close={this.closeSearchBar} />}
						</div>
					</Content>
				</Section>
			</Container>
		);
	}
}

export default Header;
