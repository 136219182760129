import React from 'react';
import { useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { NotificationContainer } from 'react-notifications';
import { Row } from 'react-styled-flexboxgrid';

import { Background, ContainerForms, BackgroundImage } from './FooterContact.styled';
import SubscribeForm from './SubscribeForm';
import ContactForm from './ContactForm';

const FooterContact = () => {
	const { file } = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "contact_background_footer.png" }) {
				childImageSharp {
					fluid(maxWidth: 1100, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<Background>
			<BackgroundImage fluid={file.childImageSharp.fluid} objectFit="cover" />
			<Row center="xs" middle="xs" style={{ position: 'relative', zIndex: 2 }}>
				<ContainerForms xs={11} lg={9}>
					<div style={{ color: 'white', maxWidth: '400px' }}>
						<h2>¿Le gustaría una asesoría gratuita?</h2>
						<p>
							Le ofrecemos asesoría gratuita durante 20 min, para ayudarle con sus problemas contables y
							fiscales.
						</p>
						<ContactForm />
					</div>
					<div style={{ color: 'white', maxWidth: '400px' }}>
						<h2>¡Recibe información en tu email!</h2>
						<p>Sólo recibirás información relevante del sector Fiscal, Contable y Fininanciero.</p>
						<SubscribeForm />
					</div>
				</ContainerForms>
			</Row>
			<NotificationContainer />
		</Background>
	);
};

export default FooterContact;
