import styled from 'styled-components'
import { SearchBox, Hits, PoweredBy } from 'react-instantsearch-dom'
import { BP_MEDIUM } from '../../styles/theme'

const ContainerContentSearch = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  @media only screen and (max-width: ${BP_MEDIUM}) {
    top: 15px;
  }
`

const SearchBoxCustom = styled(SearchBox)`
  .ais-SearchBox-input {
    height: 48px;
    width: 100%;
    border-radius: 20px 20px 0 0;
    background-color: white;
    border: none;
    font-size: 1.2rem;
    padding: 0.35rem 0 0.25rem 3rem;
    &:focus {
      outline: none;
    }
  }
  .ais-SearchBox-submit {
    left: 0.9rem;
  }
  .ais-SearchBox-submitIcon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .ais-SearchBox-reset {
    right: 0.9rem;
  }
`
const CustomHits = styled(Hits)`
  margin-top: 0.35rem;
  background-color: #ebedfd;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  box-shadow: var(--card-shadow);
  color: ${props => props.theme.backgroundFooter};

  & > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    & > li {
      cursor: pointer;
      display: block;
      background-color: white;
      margin: 0.25rem 0;
      padding: 8px 16px;
      width: 100%;
      box-shadow: none;
      border: none;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0.5rem;
      }
      &:hover {
        background-color: #ebedfd;
      }
    }
  }
`
const HitDescription = styled.p`
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const HitTitle = styled(HitDescription)`
  font-weight: bold;
  -webkit-line-clamp: 2;
`
const PoweredByCustom = styled(PoweredBy)`
  background-color: white;
  position: relative;
  z-index: 10;
  border-radius: 0 0 20px 20px;
  padding: 0.45rem 1rem;
  display: flex;
  justify-content: flex-end;
  box-shadow: var(--card-shadow);
`

export {
  ContainerContentSearch,
  CustomHits,
  HitTitle,
  HitDescription,
  PoweredByCustom,
  SearchBoxCustom,
}
